.loaderContainer {
  width: 98%;
  height: 100vh;
  position: absolute;
  top: 0px;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.08);
  transition: all 300ms ease-in-out;
}

.loader {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.bottomNavbar > button {
  width: auto;
  flex: 0;
}
