@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("variables.css");

body {
  background-color: var(--background-primary) !important;
  margin: 10px;
  font-family: "Mulish", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-family: var(--font-family-primary);
  font-style: var(--font-style-primary);
  font-weight: var(--font-weight-3);
  font-size: var(--font-size-6);
  line-height: var(--line-height-9);
  color: var(--primary-blue);
}

h2 {
  font-family: var(--font-family-primary);
  font-style: var(--font-style-primary);
  font-weight: var(--font-weight-3);
  font-size: var(--font-size-6);
  line-height: var(--line-height-9);
  color: var(--primary-blue);
}

h6 {
  display: flex;
  flex-direction: row;
  color: var(--red);
  font-family: var(--font-family-primary);
  font-style: var(--font-style-primary);
  font-weight: var(--font-weight-1);
  font-size: var(--font-size-4);
  line-height: var(--line-height-4);
  letter-spacing: var(--letter-spacing-1);
  color: var(--medium-grey);
  margin-top: 10px;
  margin-bottom: 10px;
}
h6:before,
h6:after {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid var(--medium-grey);
  margin-right: 15px;
  margin-left: 15px;
  margin-bottom: 6px;
}

h5 {
  font-family: var(--font-family-primary);
  font-style: var(--font-style-primary);
  font-weight: var(--font-weight-1);
  font-size: var(--font-size-2);
  line-height: var(--line-height-4);
  letter-spacing: var(--letter-spacing-1);
  color: var(--medium-grey);
}

p {
  font-family: var(--font-family-primary);
  font-style: var(--font-style-primary);
  font-weight: var(--font-weight-1);
  font-size: var(--font-size-2);
  line-height: var(--line-height-4);
  letter-spacing: var(--letter-spacing-1);
  color: var(--black);
}
