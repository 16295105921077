@import url("variables.css");

.objectCard {
  max-width: 581px;
  margin: auto;
  margin-top: 5px;
  background-color: var(--white) !important;
  color: #b9b9b9 !important;
  box-shadow: 9px 9px 16px rgb(163, 177, 198, 0.6),
    -9px -9px 16px rgba(255, 255, 255, 0.5) !important;

  border-radius: 30.1527px 30.1527px 0px 0px !important;
}

.modalCard {
  padding: 20px;
  margin: auto;
  margin-top: 6px;
  background: var(--white);
  box-shadow: 0px 0px 8px #dfe2eb;
  border-radius: 8px;
}

.fabStyle {
  z-index: 99;
  position: fixed;
  bottom: 70px;
  right: 40px;
}

.logo {
  height: 6rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
}

.logoNavBar {
  height: 2.5rem;
}

.spaceformItems {
  margin-top: 1rem;
}

.ellipse2 {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 40%;
  height: 40%;
  background: #77adff;
  border-radius: 50%;
  opacity: 0.21;
  filter: blur(67px);
  z-index: -1;
}

.ellipse4 {
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 40%;
  height: 40%;
  background: #8fffd7;
  border-radius: 50%;
  opacity: 0.21;
  filter: blur(67px);
  z-index: -1;
}

.ellipse3 {
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 40%;
  height: 40%;
  background: #8fd0ff;
  border-radius: 50%;
  opacity: 0.21;
  filter: blur(67px);
  z-index: -1;
}

.textCenter {
  text-align: center;
}

.EditOutlinedIcon {
  color: var(--medium-grey);
}

.personAddAltOutlinedIcon {
  color: var(--green);
}

.spaceItems {
  margin-top: 6px;
}
