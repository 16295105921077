.circularChart {
  display: block;
  margin: 10px auto;
  max-width: 50%;
  max-height: 180px;
}

.circle {
  fill: none;
  stroke-width: 2.8;
  stroke-linecap: round;
  animation: progress 500ms ease-out forwards;
}

.circleContent {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}

.circleValue {
  font-size: 2.188rem;
  color: var(--black);
  line-height: 44px;
  font-weight: 700;
}

.valueWrapper {
  text-align: center;
  position: relative;
  align-items: center;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}

