@import url("../../variables.css");

.link {
  font-family: var(--font-family-primary);
  font-style: var(--font-style-primary);
  font-weight: var(--font-weight-2);
  font-size: var(--font-size-5);
  line-height: var(--line-height-4);
  letter-spacing: var(--letter-spacing-1);
  text-decoration-line: underline;
  color: var(--black);
  margin-top: 0.1rem;
}

.greenSpan {
  color: var(--green);
}

.redSpan {
  color: var(--red);
}
