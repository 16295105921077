.titleTopMetrics {
  font-weight: 800;
  text-align: left;
}

.metricName {
  width: 100%;
  height: 56px;
  background: var(--white);
  box-shadow: 0px 0px 8px #dfe2eb;
  border-radius: 8px;
  font-family: var(--font-family-primary);
  font-style: var(--font-style-primary);
  font-weight: var(--font-weight-1);
  font-size: var(--font-size-4);
  line-height: var(--line-height-6);
  color: var(--black);
  cursor: pointer;
}

.paddingCard {
  padding: 20px !important;
}

.cardValue {
  font-size: 20px;
  line-height: 50px;
  text-align: right;
  height: 70px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
}

.variationDown {
  font-size: 12px;
  text-align: right;
  color: red;
  margin-top: -17px;
}

.variationUp {
  font-size: 12px;
  text-align: right;
  color: green;
  margin-top: -17px;
}

.valueWrapper {
  text-align: center;
}

.templateMenuContainer {
  display: flex;
  flex-direction: row;
  justify-content: end;
  width: 100%;
  margin-top: 10px;
}

.templateMenuIcon {
  width: 10px !important;
  height: 30px;
  margin-right: 10px;
  color: grey;
  text-shadow: none;
  cursor: pointer;
}

.greenSpan {
  color: var(--green);
}

