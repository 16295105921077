.container {
  text-align: center;
  margin-bottom: 20px;
}

.departmentName {
  text-align: left;
}

.formControl {
  margin-top: 15px !important;
}

.para {
  font-size: var(--font-size-4);
  line-height: var(--line-height-6);
}

.filterButton {
  width: 113px;
  height: 35px;
  font-family: var(--font-family-primary);
  font-style: var(--font-style-primary);
  font-weight: var(--font-weight-1);
  font-size: var(--font-size-2);
  line-height: var(--line-height-4);
}

.filterOutlinedButton {
  color: var(--primary-blue);
  background: rgba(233, 238, 255, 0.82);
  border-radius: 40px;
}

.filterTextButton {
  color: var(--black);
}
