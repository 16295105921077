.background {
  background-color: rgb(238, 238, 238) !important;
  width: 100%;
  height: 100vh;
  text-align: center;
  position: absolute;
}

.container {
  padding: 50px;
  max-width: 500px;
  margin: auto;
  margin-top: 50px;
}

.departmentCard {
  margin: auto;
  margin-top: 15px;
  max-width: 500px;
}

.formSelect {
  margin-bottom: 10px;
}
