.userRolItem {
  display: flex;
  padding: 10px;
  line-height: 35px;
}

.email {
  width: 55%;
}

.status {
  width: 20%;
} 

 .rol {
   width: 20%;
}

.delete{
  width: 25%;
  text-align: right;
}

.deletebutton{
  padding: 0px 6px !important;
}
